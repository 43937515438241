.highlights-logo {
  margin-right: -20px;
  img {
    margin-top: 2px;
    margin-bottom: 4px;
    max-height: 50px;
    border: 1px solid var(--bs-gray-300);
  }
}

.highlights-row {
  font-size: 90%;
}

.highlights-row:hover:not([data-show-on-map="false"]),
.program-row:hover:not([data-show-on-map="false"]) {
  cursor: pointer;
  background-color: var(--bs-gray-200);
}

.highlights-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-title-wrapper {
  position: relative;
  h3 {
    margin-top: 5px;
    margin-bottom: 0;
  }
}

.social-links-wrappers {
  cursor: pointer;
}

.social-links-highlights {
  width: 100%;
  height: 34px;
  justify-content: space-between;
  align-items: center;
  margin-right: 15px;
  left: 0;
  z-index: 5;
  padding-right: 45px;
  background-color: #fff;
  svg {
    width: 24px;
    height: 24px;
    fill: #888;
    transition: fill 500ms linear;
    &:hover {
      fill: #3f3f3f;
    }
  }
}

.copy-link {
  cursor: pointer;
}

.h-copy {
  svg {
    pointer-events: none;
  }
}
.share-content {
  z-index: 9;
  height: 34px;
  svg {
    pointer-events: none;
    width: 34px;
    height: 34px;
    fill: #888;
  }
}
